<template>
  <b-form>
    <b-row>
      <b-col md="12">
        <b-form-group label="Заголовок">
          <b-form-input v-model="form.title" placeholder="Заголовок" :state="formErrors.title"/>
        </b-form-group>
        <b-form-group label="Краткое описание">
          <b-form-textarea v-model="form.subtitle" placeholder="Краткое описание" :state="formErrors.subtitle"></b-form-textarea>
        </b-form-group>
        <b-form-group>
          <img v-if="form.hasImage" class="news__img"
               :src="form.img"
               alt="#" loading="lazy" decoding="async"
          />
          <b-form-file
              v-model="form.img"
              placeholder="Выберите изображение"
              drop-placeholder="Положите файл здесь..."
              @change="form.hasImage = false"
          />
        </b-form-group>
        <b-form-group label="Текст новости">
          <vue-editor v-model="form.content"></vue-editor>
        </b-form-group>
        <b-form-group label="Дата">
          <b-form-datepicker id="news-date" v-model="form.date" locale="ru" :start-weekday="1" label-no-date-selected="Дата" :state="formErrors.date"/>
        </b-form-group>
        <b-form-checkbox v-model="form.isActive">
          Активность
        </b-form-checkbox>
      </b-col>
    </b-row>
    <div class="text-right mt-4">
      <b-button :variant="btnVariant" @click="onSave">{{ btnTitle }}</b-button>
    </div>
  </b-form>
</template>

<script>
import { VueEditor } from "vue2-editor"
import validate from "@/utils/formValidator"
import NEWS from "@/forms/NEWS"

const NEWS_INIT_FORM = () => ({
  title: '',
  subtitle: '',
  content: '',
  img: null,
  hasImage: false,
  date: null,
  isActive: false
})

export default {
  name: "Form",
  props: {
    init: { required: false, default: () => NEWS_INIT_FORM() },
    btnVariant: { required: true },
    btnTitle: { required: true }
  },
  data() {
    return {
      form: this.init,
      errors: {}
    }
  },
  watch: {
    form: {
      handler: function () {
        this.validateForm();
      },
      deep: true
    }
  },
  computed: {
    formErrors: function () {
      return Object.fromEntries(Object.entries(this.errors).map((item) => [item[0], !item[1]]))
    }
  },
  methods: {
    fillForm(form) {
        Object.assign(this.form, form)
    },
    resetForm() {
      Object.assign(this.form, NEWS_INIT_FORM())
      this.errors = {}
    },
    validateForm() {
      this.errors = validate(this.form, NEWS)
      return Object.keys(this.errors).length === 0
    },
    onSave() {
      if (!this.validateForm())
        return

      this.$emit('onSave', this.form);
    }
  },
  components: {
    VueEditor
  }
}
</script>

<style scoped>
  .news__img {
    width: 300px;
    height: auto;
    margin-bottom: 20px;
  }
</style>